<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <img class="check" src=" " alt="" />
      <h6>Customer Files</h6>

      <div class="w-full grid gap-1 grid-cols-1 md:grid-cols-5 my-4 mb-16 px-6">
        <a
          v-for="doc in customerDocs"
          :key="doc._id"
          :href="`${api_host}/uploads/${doc.file}`"
          target="_blank"
          class="
            cursor-pointer
            bg-gray-200
            mx-3
            my-3
            text-center
            rounded-2xl
            p-4
            transform
            hover:shadow-xl hover:-translate-y-1 hover:scale-108
            duration-300
            ease-in-out
          "
        >
          <p>
            <svg
              class="mx-auto mb-4"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6"
              />
            </svg>
          </p>
          <h1 class="uppercase truncate text-black font-bold text-xs py-1">
            {{ doc.name }}
          </h1>
        </a>
      </div>

      <div class="close" @click="$emit('close-modal')">
        <svg
          class="close-img"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="red"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </div>
    </div>
  </div>
</template>
 



<script>
export default {
  name: "FilesModal",
  props: ["customerDocs"],
  data() {
    return {
      api_host: process.env.VUE_APP_API_HOST,
    };
  },
  methods: {},

  components: {},
};
</script>


<style scoped>
/* 
style is already included globally
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.modal-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  z-index: 10002;
  text-align: center;
  background-color: white;
  height: 70%;
  width: 800px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
  position: relative;
}
.close {
  margin: 1% 0 0 16px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 0;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #d4b376;
  width: 150px;
  height: 20rem;
  color: black;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}

button:hover {
  background-color: #251a05;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}
</style>

 